import React from 'react';
import ResourceCard from '../ResourceCard/ResourceCard';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import PropTypes from 'prop-types';
import './ResourceSlide.css';


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >
        {text}
    </div>
  );
};
  
  
const ArrowLeft = Arrow({
  text: <div className='right-arrow mr-6'>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
      <rect width="32" height="32" rx="16" transform="matrix(-1 0 0 1 36 2)" fill="white"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.636 12.0001L23.0503 13.4143L18.1005 18.364L23.0503 23.3138L21.636 24.728L16.6863 19.7782L15.2721 18.364L16.6863 16.9498L21.636 12.0001Z" fill="#EC1C24"/>
      <defs>
      <filter id="filter0_d" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="2"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      </defs>
    </svg>

  </div>,
  className: 'arrow-prev w-20 -mr-20 h-64 lg:h-56 flex items-center justify-end'
});

const ArrowRight = Arrow({
  text: <div className='right-arrow ml-6'>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
    <rect x="4" y="2" width="32" height="32" rx="16" fill="white"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.364 12.0001L16.9497 13.4143L21.8995 18.364L16.9497 23.3138L18.364 24.728L23.3137 19.7782L24.7279 18.364L23.3137 16.9498L18.364 12.0001Z" fill="#EC1C24"/>
    <defs>
    <filter id="filter0_d" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="2"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
  </svg>

  </div>,
  className: 'arrow-next w-20 -ml-20 h-64 lg:h-56 flex items-center justify-start'
});


const ResourceSlide = ({title, resources, extraMargin, ...props}) => {
  const titleMargin = extraMargin ? 'ml-custom' : 'pl-2';
  const properties = {
    alignCenter: false,
    clickWhenDrag: false,
    dragging: true,
    hideArrows: true,
    hideSingleArrow: true,
    arrowDisabledClass: 'opacity-0',
    arrowClass: 'cursor-pointer z-10',
    menuClass: 'slide-menu',
    innerWrapperClass: `flex pt-2 ${extraMargin? '' : 'px-2'}`,
    scrollToSelected: false,
    translate: 0,
    transition: 0.3,
    wheel: true,
    disableTabindex: true,
    useButtonRole: false,
  };

  const resourceItems = resources.map(
    (resource, resourceIndex) => {

      // add extra margins to first and last resouce if we want a wide slide
      let margin;
      if (extraMargin) {
        if (resourceIndex === 0) {
          margin = {className: 'ml-custom mr-6'}
        }
        else {
          margin = { 
            className: resourceIndex+1 === resources.length ? 'mr-custom' : 'mr-6',
          }
        }
      }
      else {
        margin = {
          className: resourceIndex+1 === resources.length ? '' : 'mr-6',
        };
      }

      return (
        <ResourceCard
          key={`${resource.node.name}-${resourceIndex}`}
          {...resource.node}
          {...margin}
        />
      );
    }
  );


  return (
    <>
    {resources.length > 0 ? <h2 className={`uppercase font-heading text-sm text-gray-400 font-bold mb-2 ${titleMargin}`}>{title}</h2> : ''}
    <ScrollMenu
      data={resourceItems}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      {...properties}
      {...props}
    />
    </>
  );
};

ResourceSlide.propTypes = {
  title: PropTypes.string,
  resources: PropTypes.array,
  extraMargin: PropTypes.bool,
}

ResourceSlide.defaultProps = {
  title: PropTypes.null,
  resources: null,
  extraMargin: false,
}

export default ResourceSlide;